<template>
  <div class="d-flex flex-column mt-4 px-2">
    <div
      v-if="isProcessing && !withPrompt"
      class="d-flex flex-column justify-center align-center pa-4"
    >
      <div class="caption-1">
        {{ $t("content.recordings.brain_tool_processing") }}
      </div>
      <div class="d-flex justify-center py-4">
        <EvercamLoadingAnimation size="FourXl" />
      </div>
    </div>

    <div v-if="error" class="d-flex justify-center">
      <v-alert type="error" width="250">
        {{ $t("content.recordings.brain_tool_error") }}
      </v-alert>
    </div>

    <v-data-table
      v-else-if="chatGptHazardsList.length && !withPrompt && !isProcessing"
      :items="chatGptHazardsList"
      :headers="headers"
      class="hazards-table"
      hide-default-footer
    >
      <template #item.concerns="{ item }">
        <div class="py-2">
          {{ item.concerns }}
        </div>
      </template>
      <template #item.actions="{ item }">
        <ul class="py-2">
          <li v-for="(action, i) of item.actions" :key="i">
            {{ action }}
          </li>
        </ul>
      </template>
    </v-data-table>

    <ChatContainer
      v-else-if="chatGptHazardsList.length === 0 && withPrompt"
      :is-processing="isProcessing"
      :chatHistory="chatHistory"
      class="mt-4"
    />
    <v-textarea
      v-if="withPrompt"
      v-model="prompt"
      label="Ask chatGpt"
      outlined
      solo
      dense
      flat
      rows="1"
      append-icon="fas fa-arrow-up"
      class="mt-4"
      auto-grow
      @click:append="askChatGpt"
    />
  </div>
</template>

<script lang="ts">
import { AiApi } from "@evercam/shared/api/aiApi"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import ChatContainer from "@evercam/shared/components/chatGpt/ChatContainer"
export default {
  name: "AskChatGpt",
  components: {
    ChatContainer,
    EvercamLoadingAnimation,
  },
  props: {
    timestamp: {
      type: String,
      required: true,
    },
    cameraExid: {
      type: String,
      required: true,
    },
    withPrompt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          value: "hazardCategory",
          text: "Hazard Category",
          width: 50,
          sortable: true,
          filterable: true,
        },
        {
          value: "concerns",
          text: "Concerns",
          width: 75,
          filterable: false,
          sortable: false,
        },
        {
          value: "actions",
          text: "Actions / Checklist items",
          width: 120,
          filterable: false,
          sortable: false,
        },
      ],
      isProcessing: false,
      chatGptHazardsList: [],
      retries: 0,
      error: false,
      prompt: `This is a snapshot from a construction site at a given point in time.
          I want you to look at it from the perspective of of a construction safety specialist and highlight any concerns you may notice on the snapshot.

          give me the results only, in the following format:
          [
            {
              hazardCategory: "", // category of the hazard. Examples: 'PPE Compliance','Heavy Machinery','Site Perimeter','Trip Hazard','Dust Control','Falling Debris','Traffic Management','Material Storage','Noise Management','Utility Safety','Excavation Safety','Equipment Positioning','Environment Conditions', 'Signage & Fencing'...etc
              concerns: "", // Short description of the concerns with the hazard in one sentence. Examples: 'Monitor operations zones and operator visibility', 'Prevent Debris from leaving the area', 'Ensure protective systems for trenches', 'Maintain clear warning and informational signs'...etc
              actions: [], // Short list of actions to be taken. Examples: ['Establish exclusion zones', 'Use spotters for blind spots']...etc
            },
            //...etc
          ]

          I'm going to use your reply to display a table.
          Important: don't use expressions such as "appears to be" or "may not" or "seems to"...etc use a confident and assertive tone.
          Very important: Give me a the answer as a JSON, no line breaks, no escape characters. Don't stop while generating the answer. Make sure it's not an Unterminated string in JSON.
          Extremely important: Make sure it's not an Unterminated string in JSON. I'll parse your reponse exactly as it is using JSON.parse and give me max: 5 results.
        `,
      chatHistory: [],
    }
  },
  watch: {
    isProcessing(val) {
      this.$emit("is-processing", val)
    },
    timestamp: {
      immediate: true,
      handler() {
        if (this.withPrompt) {
          return
        }
        this.askChatGpt()
      },
    },
  },
  mounted() {
    if (this.withPrompt) {
      this.prompt = ""
    }
  },
  methods: {
    async askChatGpt() {
      this.isProcessing = true
      this.error = false
      if (!this.timestamp) {
        this.isProcessing = false

        return
      }

      try {
        const data = await AiApi.brainTool.getSecurityMesurement(
          this.cameraExid,
          {
            timestamp: this.timestamp,
            prompt: this.prompt,
          }
        )

        try {
          const results = JSON.parse(data?.response)
          if (!this.withPrompt) {
            this.chatGptHazardsList = results
          } else {
            if (Array.isArray(results)) {
              this.chatHistory = [
                ...this.chatHistory,
                {
                  role: "user",
                  content: this.prompt,
                },
                {
                  role: "assistant",
                  content: JSON.parse(data?.response),
                  isArray: true,
                },
              ]
            } else {
              this.chatHistory = [
                ...this.chatHistory,
                {
                  role: "user",
                  content: this.prompt,
                },
                {
                  role: "assistant",
                  content: data?.response,
                },
              ]
            }
          }
        } catch (error) {
          if (this.withPrompt) {
            this.chatHistory = [
              ...this.chatHistory,
              {
                role: "user",
                content: this.prompt,
              },
              {
                role: "assistant",
                content: data?.response,
              },
            ]
          }
        }
        this.isProcessing = false

        this.prompt = ""

        return
      } catch (error) {
        this.retries++
        if (this.retries <= 2) {
          return this.askChatGpt()
        }
        this.error = true
        this.$notifications.error({
          text: this.$t("content.fetch_resource_failed", {
            resource: "brainTool results",
          }),
          error,
          notifyParams: {
            ERROR: error,
            REQUEST_PAYLOAD: {
              cameraExid: this.cameraExid,
              timestamp: this.timestamp,
            },
            FEATURE: "BrainTool",
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.hazards-table {
  border: 1px solid var(--dash-border-color);
}
.v-input {
  &__append-inner {
    padding: 0.25rem !important;
    background-color: var(--v-primary-base) !important;
    border-radius: 0.25rem !important;
    color: white;
  }
}
.v-icon.v-icon.v-icon--link {
  color: white !important;
}
.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense
  .v-input__append-inner {
  margin-bottom: 8px;
  align-self: flex-end;
  background-color: var(--v-primary-base) !important;
  border-radius: 0.25rem !important;
  color: white;
}
</style>
