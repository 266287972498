<template>
  <div
    class="bounding-box"
    :class="{
      'bounding-box--active': highlighted,
      [`object-${label} bounding-box-${label}`]: true,
    }"
    :style="boundingBoxStyle"
  >
    <span class="bounding-box__label">
      <slot>
        {{ label }}
      </slot>
    </span>
  </div>
</template>

<script lang="ts">
import Vue, { type PropType } from "vue"
import type { DetectionLabel } from "@evercam/shared/types"

export default Vue.extend({
  name: "BoundingBox",
  props: {
    label: {
      type: String as PropType<DetectionLabel>,
      required: true,
    },
    xMin: {
      type: Number,
      required: true,
    },
    yMin: {
      type: Number,
      required: true,
    },
    xMax: {
      type: Number,
      required: true,
    },
    yMax: {
      type: Number,
      required: true,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    boundingBoxStyle(): Record<string, string> {
      const coords = {
        left: this.xMin,
        top: this.yMin,
        height: this.yMax - this.yMin,
        width: this.xMax - this.xMin,
      }

      return {
        top: `${coords.top * 100}%`,
        left: `${coords.left * 100}%`,
        height: `${coords.height * 100}%`,
        width: `${coords.width * 100}%`,
        transition: "width 0.2s, height 0.2s",
      }
    },
  },
})
</script>

<style scoped lang="scss">
$objects-colors: (
  "articulated-dumper": #ba68c8,
  "backhoe-loader": #42a5f5,
  "compact-excavator": #ce93d8,
  "concrete-pump": #ffcc80,
  "dumper": #1976d2,
  "excavator": #1e88e5,
  "flatbed-semi-trailer": #ffd600,
  "industrial-truck": #fb8c00,
  "loader": #64b5f6,
  "mewps": #a5d6a7,
  "mobile-crane": #ffa726,
  "mobile-crusher": #81c784,
  "other-truck": #ef5350,
  "pile-drilling-rig": #4db6ac,
  "road-vehicle": #66bb6a,
  "roller": #80cbc4,
  "semi-trailer": #ffff8d,
  "skid-steer-loader": #ab47bc,
  "skip-truck": #135d17,
  "small-truck": #ef9a9a,
  "tank-truck": #ffea00,
  "tele-handler": #f57c00,
  "tipping-truck": #43a047,
  "tower-crane": #ffb74d,
  "tower-mounted-concrete-mix": #ca6d16,
  "tractor-dozer": #90caf9,
  "truck-head": #e57373,
  "truck-mixer": #26a69a,
  "truck-mounted-mobile-crane": #a06f20,
  "unknown": #9e9e9e,
  "worker": #e53935,
  "Crane-A": #1b77d2,
  "Crane-B": #d36511,
  "Crane-C": #519640,
  "Crane-D": #607d8b,
  "Crane-E": #e91e63,
  "Crane-F": #ffeb3b,
  "Crane-G": #3f51b5,
  "Crane-H": #ffcdd2,
  "Mobile-Crane-A": #673ab7,
  "Mobile-Crane-B": #ff9800,
  "Mobile-Crane-C": #795548,
  "Mobile-Crane-D": #607d8b,
  "Mobile-Crane-E": #9c27b0,
  "Mobile-Crane-F": #00bcd4,
  "Mobile-Crane-G": #009688,
  "Mobile-Crane-H": #ffeb3b,
);

@each $object, $color in $objects-colors {
  .object-#{$object} {
    &::after,
    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid #{$color};
      box-shadow: 0 0 5px 0 #{$color};
      z-index: 10000;
    }
    &:hover::before,
    &.bounding-box--active::before {
      border-width: 0;
    }
    &:hover::after,
    &.bounding-box--active::after {
      animation: border-highlight 0.2s alternate infinite;
    }
    & > .bounding-box__label {
      color: $color;
    }
    &:hover .bounding-box__label,
    &.bounding-box--active .bounding-box__label {
      text-shadow: -1px -1px 1px #{$color}, 1px -1px 1px #{$color},
        -1px 1px 1px #{$color}, 1px 1px 1px #{$color};
      color: #333;
    }
  }
}

.bounding-box {
  position: absolute;
  user-select: none;
  &__label {
    position: absolute;
    user-select: none;
    top: -1.5rem;
    font-size: 1rem;
    text-shadow: -1px -1px 1px #333, 1px -1px 1px #333, -1px 1px 1px #333,
      1px 1px 1px #333;
    overflow: visible;
    font-weight: 500;
    letter-spacing: 1.5px;
    white-space: nowrap;
  }
}

@keyframes border-highlight {
  25% {
    border-width: 2px;
  }
  50% {
    border-width: 3px;
  }
  100% {
    border-width: 4px;
  }
}
</style>
