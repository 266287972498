<template>
  <v-dialog v-model="addLogoDialog" width="400px">
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="isIconButton"
        color="primary"
        class="mt-n2"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small> fa-plus </v-icon>
      </v-btn>
      <v-btn v-else small color="primary" v-bind="attrs" v-on="on">
        <v-icon left small class="add-logo">fas fa-plus</v-icon>
        new
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Upload Logo</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-3" style="max-height: 300px">
        <v-file-input
          v-model="newLogo"
          prepend-icon="fa-image"
          accept="image/png, image/jpeg"
          label="Upload Logo"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="addLogoDialog = false">
          CANCEL
        </v-btn>
        <v-btn
          text
          color="primary"
          :disabled="!newLogo"
          :loading="isLoading"
          @click="addLogo"
        >
          SAVE
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { AnalyticsEvent } from "@evercam/shared/types"
export default {
  name: "AddProjectLogoDialog",
  props: {
    projectId: {
      type: [String, undefined],
      default: undefined,
    },
    isIconButton: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      addLogoDialog: false,
      newLogo: null,
      isLoading: false,
    }
  },
  watch: {
    addLogoDialog(visible) {
      this.$analytics.saveEvent(
        AnalyticsEvent.TimelapseToggleUploadLogoDialog,
        { visible }
      )
    },
  },
  methods: {
    async addLogo() {
      try {
        this.$analytics.saveEvent(AnalyticsEvent.TimelapseUploadLogo)
        this.isLoading = true
        const formData = new FormData()
        formData.append("name", this.newLogo.name)
        formData.append("logo", this.newLogo)
        await EvercamApi.projects.createLogo(this.projectId, formData)
        this.$emit("logo-added")
        this.$notifications.success(this.$t("content.logo.add_success"))
        this.addLogoDialog = false
        this.newLogo = null
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.logo.add_error"),
          error,
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
